jQuery(document).ready(function() {
  $(".text-uppercase").keyup(function () {
    this.value = this.value.toLocaleUpperCase();
  });

  $('body').on("click", "#download_file", function(){
    let url = "/download_credit_calculator.xlsx?credit_amount="+ $('#credit_amount').val() +
              "&discount_number="+ $("#discount_number").val() + "&interest_rate=" + $("#interest_rate").val() +
              "&saving=" + $("#saving").val()
    window.open(url, '_blank');
  });

  $( "#saving_date").change(function() {
    alert( "Handler for .change() called." );
  });
});
