jQuery(document).ready(function() {
  $('#users-datatable').DataTable({
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "ajax": {
      "url": $('#users-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "name"},
      {"data": "email"},
      { "data": null,
        "render": function(data){
          if (data.status == "true") {
            return "<center><i class='fas fa-user' style='color: green;'><i></center>"
          } else {
            return "<center><i class='fas fa-user-slash' style='color: red;'><i></center>"
          }
        }
      }
    ]
  });

  $('body').on('change', '#user_role', function(evt){
    var role = $("#user_role").val();
    var status = (role == 'Socio' ? false : 'disabled')
    $('#user_partner_id').prop('disabled', status);
    if (role != 'Socio') {
      var elements = [
                  'user_email', 'user_name', 'user_last_name', 'user_second_last_name',
                  'user_password', 'user_password_confirmation'
                  ]
      for (var i = 0; i < elements.length; i++) {
        $('#' + elements[i]).val('');
      }
      $("#user_partner_id").select2("val", "0");
    }
  });

  $("#user_partner_id").select2({
    ajax: {
      url: "/partners/search_partners",
      type: "get",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          searchTerm: params.term
        };
      },
      processResults: function (response) {
        return response
      },
      cache: true
    }
  });

  $('#user_partner_id').on('select2:select', function (e) {
   var data = e.params.data;
   $.ajax({
     url: "/partners/" + data.id,
     dataType: "json",
   }).done(function( data ) {
     $('#user_email').val(data.partner.email)
     $('#user_name').val(data.partner.name)
     $('#user_last_name').val(data.partner.last_name)
     $('#user_second_last_name').val(data.partner.seond_last_name)
     $('#user_password').val(data.partner.curp)
     $('#user_password_confirmation').val(data.partner.curp)
   });
  });
});
