jQuery(document).ready(function() {
  $('#credit_applications-datatable').DataTable({
    "order": [[4, "desc"],
              [0, "desc"]],
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "ajax": {
      "url": $('#credit_applications-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "no_request"},
      {"data": "no_nomina"},
      {"data": "name"},
      {"data": "amount"},
      {"data": "date_request"},
      {"data": "status"}
    ]
  });
  $("#search_partner").click(function(){
    $("#partner_datas").empty();
    $.ajax({
      url: "/search_partner",
      dataType: "script",
      data: { no_nomina: $("#no_nomina_id").val() }
    })
  })

  $('#credit_application_no_nomina_aval').change(function(){
    $('#status_aval').css('visibility', 'hidden');
    var aval_nomina = $(this, "option:selected").val();
    $.ajax({
      url: "/search_aval",
      dataType: "json",
      data: { no_nomina: aval_nomina }
    }).done(function( data ) {
      status = data.status ? 'visible' : 'hidden'
      $('#status_aval').css('visibility', status);
    });
  })
});
