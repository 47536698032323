jQuery(document).ready(function(){
  $('#authorized_credit-datatable').DataTable({
    "order": [[5, "desc"],
              [0, "desc"]],
    "columnDefs": [
      {
        "searchable": false,
        "targets": [ 6 ]
        }
    ],
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "ajax": {
      "url": $('#authorized_credit-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "no_authorized"},
      {"data": "no_nomina"},
      {"data": "partner"},
      {"data": "status"},
      {"data": "amount"},
      {"data": "date_authorized"},
      {"data": "fixed_fee"},
      {"data": "type_credit"}
    ]
  });
  $(".calculate").blur(function(){
    let discount_number = Number($("#authorized_credit_time_limit").val())
    let credit_amount = Number($("#authorized_credit_amount").val())
    let saving = Number($("#authorized_credit_saving").val())
    let interest_rate = Number($("#authorized_credit_interest_rate").val())
    let discount = 0.0
    if (interest_rate > 0) {
      interest_rate = ( interest_rate / 100 )
      let a = (interest_rate * Math.pow((1 + interest_rate),discount_number))
      let b = (Math.pow((1+interest_rate),discount_number))-1
      discount = (credit_amount * (a / b))
    } else {
      discount = (credit_amount / discount_number)
    }
    $("#authorized_credit_amortization").val(discount.toFixed(2))
    $("#authorized_credit_fixed_fee").val((discount+saving).toFixed(2))
  });
});
