// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("moment/locale/es")
require("tempusdominus-bootstrap-4")

import $ from 'jquery';
global.$ = jQuery;
import "bootstrap"
import 'cocoon-js';

require("datatables.net-bs4");
require("packs/user")
require("packs/state")
require("packs/partner")
require("packs/credit_application")
require("packs/bank")
require("packs/affiliation_unit")
require("packs/worker_category")
require("packs/helpers")
require("packs/authorized_credit")
require("packs/credit_application")
require("packs/payment_fortnight")
require("packs/system_initial_configuration")
require("packs/movements_catalog")
require("packs/saving_details")
require("packs/fortnight_register")
require("packs/credit_type")
require("packs/accounting_year")
require('packs/jquery-simple-tree-table.js')

import "tempusdominus-bootstrap-4";

import "@fortawesome/fontawesome-free/js/all";
import "../css/main.scss"

import 'select2/dist/css/select2.css'
import 'select2'

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener("DOMContentLoaded", () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "j - F - Y",
    dateFormat: "Y-m-d",
    locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        },
      }
  })
})

document.addEventListener('DOMContentLoaded', () => {
  $('.select_list').select2()
})

$(document).ready(function () {
  $('body').on('change', '#saving_partner_move_type', function(evt){
    var partner_id = $("#saving_partner_partner_id").val();
    var type_saving = $(this, 'option:selected').val()
    $.ajax({
      url: "/partners/" + partner_id + "/savings_consultation",
      dataType: "json",
      data: { type: $(this, "option:selected").val() }
    }).done(function( data ) {
      console.log(data);
      if ( type_saving == 'Retiro(Capital + Intereses)' ){
        var d = new Date();
        var n = d.getHours();
        var year = data.fortnight_registers.year
        var fortnight = data.fortnight_registers.fortnight
        var text = 'T' + String(year) + String(fortnight) + '-' + String(d.getHours()) +
                   ':' + String(d.getMinutes())

        var description = (data.partner.bank_reference === null) ? '' : data.partner.bank_reference
        description += ' Transferencia de Ahorro'
        $('#saving_partner_references_move').val(text)
        $('#saving_partner_description').val(description)
      } else {
        $('#saving_partner_references_move').val('Transferencia crédito')
        $('#saving_partner_description').val('Transferencia crédito')
      }
      if (type_saving == 'Retiro Capital'){
        $('#saving_partner_amount').val(data.amount_capital);
      }else if (type_saving == 'Retiro Intereses') {
        $('#saving_partner_amount').val(data.amount_interest);
      }
      // $('#saving_partner_amount').val(data.tariffs);
      // $('#saving_partner_interest').val(data.interest);
    });
  });


  $('body').on('change', '#credit_move_description', function(evt){
    var type_move = $(this, 'option:selected').val()
    var credit_id= $('#credit_move_authorized_credit_id').val()
    $('#calcule_amounts').css("display", "none");
    $('#credit_move_amount').val('');
        $('#credit_move_capital').val('');
        $('#credit_move_intereses').val('');
    if (type_move == "Canc. xpp") {
      $.ajax({
        url: "/credit_moves/interest_consultation?credit_id=" + credit_id,
        dataType: "json",
        data: { type: $(this, "option:selected").val() }
      }).done(function( data ) {
        $('#credit_move_amount').val(Number(data.total))
      });
    } else if(type_move == 'Capital + intereses') {
      $('#calcule_amounts').css("display", "block");
      $.ajax({
        url: "/credit_moves/fortnight_amounts?credit_id=" + credit_id,
        dataType: "json",
      }).done(function( data ) {
        $('#credit_move_amount').val(Number(data.amounts.total));
        $('#credit_move_capital').val(Number(data.amounts.capital));
        $('#credit_move_intereses').val(Number(data.amounts.interest));
      });
    } else {
      $('#credit_move_amount').val('')
    }
  });

  $('body').on('keyup', '#capital', function(){
    console.log($('#capital').val());
  });

});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true)
