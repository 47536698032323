jQuery(document).ready(function() {
  $('#worker_categories-datatable').DataTable({
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "ajax": {
      "url": $('#worker_categories-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "description"},
    ]
  });
});
