jQuery(document).ready(function() {
  $('#fortnight-registers-datatable').DataTable({
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "order": [[1, "desc"]],
    "ajax": {
      "url": $('#fortnight-registers-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "fortnight"},
      {"data": "created_at"},
      {"data": "updated_at"},
      {"data": null,
        "render": function(data){
          if (data.active_register == "true"){
            return "<center><i class='fas fa-check-circle' style='color: green;'/></center>"
          } else {
            return "<center><i class='fas fa-times-circle' style='color: red;'/></center>"
          }
        }
      }
    ]
  })
  $('#authorized_credit_type_credit').change(function(){
    var type_move = $(this, 'option:selected').val();
    $('#authorized_credit_fixed_fee').attr('readonly', type_move != 'Quincenal');
  });

});
