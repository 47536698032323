jQuery(document).ready(function() {
  $('#partners-datatable').DataTable({
    "processing": true,
    "serverSide": true,
    "pageLength": 50,
    "ajax": {
      "url": $('#partners-datatable').data('source')
    },
    "language": {
      "info": "Viendo _START_ de _END_ de _TOTAL_ registros",
      "infoEmpty": "Viendo 0 a 0 de 0 registros",
      "search": "Buscar",
      "lengthMenu": "Ver _MENU_ registros",
      "paginate": {
        "first":      "Primera",
        "last":       "Última",
        "next":       "Siguiente",
        "previous":   "Anterior"
      }
    },
    "pagingType": "simple_numbers",
    "columns": [
      {"data": "no_nomina"},
      {"data": "name"},
      {"data": "affiliation_unit"},
      {"data": "phone"},
      {"data": null,
        "render": function(data){
          if (data.condition == "Incompleto"){
            return "<center><i class='fas fa-lightbulb' style='color: red;'/></center>"
          } else if (data.condition == "Faltan Registros") {
            return "<center><i class='fas fa-lightbulb' style='color: #d8cc2e;'/></center>"
          } else {
            return "<center><i class='fas fa-lightbulb' style='color: green;'/></center>"
          }
        }
      },
      { "data": null,
        "render": function(data){
          if (data.status == "true") {
            return "<center><i class='fas fa-user' style='color: green;'><i></center>"
          } else {
            return "<center><i class='fas fa-user-slash' style='color: red;'><i></center>"
          }
        }
      },
      {"data": null,
        "render": function(data){
          if (data.saving_status == 0){
            return "<center><i class='fas fa-ban' style='color: #b5b8bd;'/></center>"
          } else if (data.saving_status == 1) {
            return "<center><i class='fas fa-dollar-sign' style='color: green;'/></center>"
          } else {
            return "<center><i class='fas fa-dollar-sign' style='color: red;'/></center>"
          }
        }
      }
    ]
  });
});
